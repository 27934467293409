<template>
  <ion-content v-show="stepVisible" fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title size="small">Genç Kaşif Kayıt Formu</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-title>Muvafakatname</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-list lines="none">
      <ion-list-header>Yüzyüze Eğitim</ion-list-header>
      <ion-item>
        <p>1.	Program süresince, gerek eğitime gidip gelirken, gerekse eğitim yerinde veya sosyal faaliyetler esnasında meydana gelebilecek her türlü kaza vb. vukuatların sorumluluğunu üstleneceğimi,</p>
      </ion-item>
      <ion-item>
        <p>2.	Genç Kâşif ders müfredatı doğrultusunda çocuğumun eğitime devamını, derslerinin ve her türlü faaliyetin takibini yaparak bu hususta gerekli ilgiyi göstereceğimi,</p>
      </ion-item>
      <ion-item>
        <p>3.	Bir eğitim yılında mazeretli 3, mazeretsiz de 2 gün olmak üzere, toplam 5 günden fazla devamsızlık durumunda, öğrenci kaydının silineceğini,</p>
      </ion-item>
      <ion-item>
        <p>4.	Proje katılım bedeli olan yıllık 1 Talebe için £400, birden fazla talebe için çocuk başına £300 (Sterlin)`i zamanında ödeyeceğimi,</p>
      </ion-item>
      <ion-item>
        <p>5.	Tanıtım çalışmalarında kullanılmak üzere resim ve video çekilmesine izin verdiğimi,</p>
      </ion-item>
      <ion-item>
        <p>6.	Öğlen yemeği için beslenme çantası hazırlayıp göndereceğimi,</p>
      </ion-item>
      <ion-item>
        <p>7.	Yüz yüze ve online yapılan tüm ders, SKSF ve aktivitelere velisi bulunduğum öğrencinin vaktinde katılım saylayacağını,</p>
      </ion-item>
      <ion-item>
        <p>8.	Verilen ödevlerin aksatılmadan yapılması için gerekli takibi, ilgi ve alakayı göstereceğimi,</p>
      </ion-item>
      <ion-item>
        <span>{{ errors['agreementStep.agreement'] }}</span>
      </ion-item>

        <ion-item>
        <ion-checkbox :v-model="agreement" @update:modelValue="agreement = $event" color="primary"
                      slot="start"></ion-checkbox>
          <p>GENÇ KAŞİF projesi yüzyüze eğitim ve online eğitim muvafakatnamelerini okuyup burada yazılan kuralları kabul ettiğimi beyan eder, çocuğumun kaydının yapılmasını rica ederim.</p>
      </ion-item>
    </ion-list>

  </ion-content>
</template>

<script>
import {
  IonCheckbox,
  IonContent,
  IonHeader,
  IonItem,
  IonList,
  IonListHeader,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {arrowBackOutline, arrowForwardOutline, helpCircle, personCircle, search, star,} from "ionicons/icons";
import {useField, useFormErrors} from "vee-validate";
import {defineComponent} from 'vue';
import * as yup from "yup";
import "yup-phone";

export const agreementStepComponentSchema = yup
    .object({
      agreementStep: yup.object({
        agreement: yup.boolean().isTrue('Kabul ettiğinizi işaretleyiniz').default(false).label('This')
      })
    })
    .required();

export default defineComponent({
  name: "parentStep",
  props: {
    'stepVisible': Boolean,
    'form': Object,
    'currentStep': Number,
    'totalSteps': Number
  },
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonCheckbox,
    IonList,
    IonListHeader
  },
  setup() {
    const {value: agreement} = useField('agreementStep.agreement');
    //const requiredMsg = '⛔ This field is required'
    return {
      arrowForwardOutline,
      arrowBackOutline,
      helpCircle,
      personCircle,
      search,
      star,
      agreement,
      errors: useFormErrors()
    }
  }
})
</script>