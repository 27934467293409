import axios from 'axios'
import router from '../router'

const COUNTRY = '/api/country'
const KASIF_APPLICATION = '/api/kasif'

axios.defaults.baseURL = 'https://b.agdavrupa.org'

axios.interceptors.request.use(function(config) {
    let token = null
    token = 'sd_token'
    // config.headers['X-Dashboard'] = 'true'
    config.headers['Content-Type'] = 'application/json'
    if (token) {
        config.headers.Authorization = `Bearer ${token}`
    }
    return config
}, function(err) {
    return Promise.reject(err)
})

axios.interceptors.response.use(function(response) {
    return response
}, function(error) {
    if (error.response.status === 401) {
        errorMessage('ReCaptcha Failed')
        return Promise.reject(error)
    } else if (error.response.status === 409) {
        // EventBus.$emit(SNACKBAR_NOTIFICATION, '409_Duplicate')
        errorMessage('409_Duplicate')
        return Promise.reject(error)
    } else if (error.response.status === 403) {
        router.push('/')
        // EventBus.$emit(SNACKBAR_NOTIFICATION, 'You are not authorized to view this page.')
        errorMessage('You are not authorized to view this page.')
        return Promise.reject(error)
    } else {
        // EventBus.$emit(SNACKBAR_NOTIFICATION, 'An error occurred.')
        errorMessage('An error occurred.')
        return Promise.reject(error)
    }
})

export default {
    getCountries() {
        return axios.get(COUNTRY)
    },
    getStates(country) {
        return axios.get(COUNTRY + '/' + country)
    },
    save(data) {
        return axios.post(KASIF_APPLICATION, data, {timeout: 5000})
    }
}

import Vue from 'vue'

export function errorMessage(text) {
    Vue.prototype.$notify.error({
        position: 'top-left',
        message: text,
        title: 'Error'
    })
}
