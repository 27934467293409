<template>
  <ion-page>

    <student-step ref="studentStep" :step-visible="studentStepVisible"/>
    <contact-step ref="contactStep" :step-visible="contactStepVisible"/>
    <parent-step ref="parentStep" :step-visible="parentStepVisible"/>
    <agreement-step ref="agreementStep" :step-visible="agreementStepVisible"/>

    <ion-footer>
      <ion-toolbar>
        <ion-buttons slot="secondary" >
          <ion-button v-show="prevBtnVisible" @click="prev" fill="solid" color="light" size="large">Geri</ion-button>
        </ion-buttons>
        <ion-buttons slot="primary">
          <ion-button :disabled="submitBtnDisabled" @click="next" fill="solid" color="primary" size="large">{{ nextBtnText }}</ion-button>
        </ion-buttons>
        <ion-title>{{ this.active+1 }} / {{this.totalSteps}} </ion-title>
      </ion-toolbar>
    </ion-footer>

  </ion-page>
</template>

<script>
import studentStep, {studentStepComponentSchema} from "@/views/components/StudentStep.vue";
import contactStep, {contactStepComponentSchema} from "@/views/components/ContactStep.vue";
import parentStep, {parentStepComponentSchema} from "@/views/components/ParentStep.vue";
import agreementStep, {agreementStepComponentSchema} from "@/views/components/AgreementStep.vue";
import {arrowBackOutline, arrowForwardOutline, helpCircle, personCircle, search, star,} from "ionicons/icons";
import {defineComponent} from 'vue';
import {IonButton, IonButtons, IonFooter, IonPage, IonTitle, IonToolbar, toastController} from "@ionic/vue";
import {useForm} from "vee-validate";
import api from "../api/api"

const customPickerOptions = {
  buttons: [
    {
      text: "Save",
      handler: () => console.log("Clicked Save!"),
    },
    {
      text: "Log",
      handler: () => {
        console.log("Clicked Log. Do not Dismiss.");
        return false;
      },
    },
  ],
};

export default defineComponent({
  name: "Tabs",
  emits: ['next', 'submitForm'],
  components: {
    studentStep,
    contactStep,
    parentStep,
    agreementStep,
    IonButton,
    IonButtons,
    IonFooter,
    IonToolbar,
    IonTitle,
    IonPage
  },
  setup() {
    const theForm = useForm({
      validationSchema: studentStepComponentSchema.concat(contactStepComponentSchema).concat(parentStepComponentSchema)
          .concat(agreementStepComponentSchema)
    })

    return {
      theForm,
      totalSteps: 4,
      arrowForwardOutline,
      arrowBackOutline,
      helpCircle,
      personCircle,
      search,
      star,
      customPickerOptions
    }
  },
  data() {
    return {
      active: 0,
      submitBtnDisabled: false
    }
  },
  methods: {
    prev: function () {
      if (--this.active < 0) this.active = 0
    },
    next: async function () {
      if (await this.validateForm() && ++this.active > (this.totalSteps - 1)) {
        this.active = this.totalSteps - 1

        const {studentStep, parentStep, contactStep} = this.theForm.values
        const submitData = {
          student: {...studentStep},
          parent: {...parentStep},
          contact: {...contactStep}
        }
        const d = new Date(submitData.student.dateOfBirth)
        submitData.student.dateOfBirth = d.getFullYear() + '-'
            + this.pad(d.getMonth() + 1) + '-'
            + this.pad(d.getDate()) + 'T'
            + this.pad(d.getHours()) + ':'
            + this.pad(d.getMinutes()) + ':'
            + this.pad(d.getSeconds())
            + '+0000'
        submitData.student.quran = { id: Number.parseInt(submitData.student.quran) }
        submitData.student.turkish = { id: Number.parseInt(submitData.student.turkish) }
        submitData.student.memorization = { id: Number.parseInt(submitData.student.memorization) }
        submitData.student.grade = Number.parseInt(submitData.student.grade)
        submitData.contact.region = { id: submitData.contact.region }
        submitData.contact.country = { id: submitData.contact.country }
        //console.log("submit form data", submitData)
        this.submitBtnDisabled = true
        await api.save(submitData).then(response => {
          if(response.status === 201) {
            this.theForm.resetForm({})
            this.active = 0
            this.openToast("Kaydınız tarafımıza ulaşmıştır", "success")
          } else {
            this.openToast("Kaydınız gönderilirken hata oluştu. Lütfen daha sonra tekrar deneyiniz.", "danger")
          }
        })
        this.submitBtnDisabled = false
      }
    },
    validateForm: async function () {
      const validationResponse = await this.theForm.validate();
      let isStepValid = true;
      const stepName = this.visibleStepName();
      Object.keys(validationResponse.results).forEach(function (key) {
        if (key.startsWith(stepName)) {
          isStepValid = isStepValid && validationResponse.results[key].valid
        }
      });
      if (isStepValid) {
        this.theForm.resetForm({values: this.theForm.values})
      }
      return isStepValid;
    },
    visibleStepName: function () {
      switch (this.active) {
        case 0:
          return 'studentStep'
        case 1:
          return 'contactStep'
        case 2:
          return 'parentStep'
        case 3:
          return 'agreementStep'
        default:
          return ''
      }
    },
    pad(n) {
      return n < 10 ? '0' + n : n
    },
    async openToast(msg, color) {
      const toast = await toastController
          .create({
            message: msg,
            duration: 4000,
            color: color
          })
      return toast.present();
    },
  },
  computed: {
    studentStepVisible: function () {
      return this.active === 0
    },
    contactStepVisible: function () {
      return this.active === 1
    },
    parentStepVisible: function () {
      return this.active === 2
    },
    agreementStepVisible: function () {
      return this.active === 3
    },
    prevBtnVisible: function () {
      return this.active > 0
    },
    nextBtnText: function () {
      return this.isFinalStep ? "Kayıt Ol" : "Devam"
    },
    isFinalStep: function () {
      return this.active + 1 === this.totalSteps
    },
    visibleStepTitle: function () {
      switch (this.active) {
        case 0:
          return 'Talebe Bilgileri'
        case 1:
          return 'Konumunuz'
        case 2:
          return 'Veli Bilgileri'
        case 3:
          return 'Muvafakatname'
        default:
          return ''
      }
    }
  }
})
</script>

<style lang="css">
ion-item span {
  font-size: 0.85em;
  color: red;
}
ion-textarea {
  height: 100%;
}
ion-textarea textarea {
  height: 100%;
}


</style>