import { createRouter, createWebHistory } from '@ionic/vue-router';
import Index from '../views/Index.vue'

const routes = [
  {
    path: '/',
    component: Index
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
