<template>
  <ion-content v-show="stepVisible" fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title size="small">Genç Kaşif Kayıt Formu</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-title>Konumunuz</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-item>
      <ion-label position="stacked">Adres <span>{{ errors['contactStep.street'] }}</span></ion-label>
      <ion-input v-model="street" autocapitalize="words"></ion-input>
      <ion-label position="stacked">Posta Kodu <span>{{ errors['contactStep.postalCode'] }}</span></ion-label>
      <ion-input v-model="postalCode"></ion-input>
      <ion-label position="stacked">Şehir <span>{{ errors['contactStep.city'] }}</span></ion-label>
      <ion-input v-model="city" autocapitalize="words"></ion-input>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Ülke <span>{{ errors['contactStep.country'] }}</span></ion-label>
      <ion-select v-model="country" placeholder="Seçiniz" interface="action-sheet" cancel-text="Kapat">
        <template v-for="c in countries" :key="c.id">
          <ion-select-option :value="c.id">{{ c.name }}</ion-select-option>
        </template>
      </ion-select>
    </ion-item>
      <ion-item>
        <ion-label position="floating">Eyalet <span>{{ errors['contactStep.region'] }}</span></ion-label>
        <ion-select v-model="region" placeholder="Seçiniz" interface="action-sheet" cancel-text="Kapat">
        <template v-for="r in regions" :key="r.id">
          <ion-select-option :value="r.id">{{ r.name }}</ion-select-option>
        </template>
      </ion-select>
    </ion-item>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {arrowBackOutline, arrowForwardOutline, helpCircle, personCircle, search, star,} from "ionicons/icons";
import {useField, useFormErrors} from "vee-validate";
import {computed, defineComponent, ref} from 'vue';
import * as yup from "yup";
//import api from '@/api/api'

export const contactStepComponentSchema = yup
    .object({
      contactStep: yup.object({
        country: yup.number().required().label('This'),
        city: yup.string().required().label('This'),
        street: yup.string().required().label('This'),
        region: yup.number().required().label('This'),
        postalCode: yup.string().required().label('This')
      })
    })
    .required();

export default defineComponent({
  name: "contactStep",
  props: {
    'stepVisible': Boolean,
    'form': Object,
    'currentStep': Number,
    'totalSteps': Number
  },
  components: {
    IonInput, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonSelect, IonSelectOption
  },
  setup() {
    const {value: country} = useField('contactStep.country');
    const {value: city} = useField('contactStep.city');
    const {value: street} = useField('contactStep.street');
    const {value: region, handleReset: resetRegion} = useField('contactStep.region');
    const {value: postalCode} = useField('contactStep.postalCode');

    const countries = ref([
      {
        "id": 1,
        "name": "Almanya",
        "states": [
          {
            "id": 1,
            "name": "Baden-Württemberg"
          },
          {
            "id": 2,
            "name": "Bayern"
          },
          {
            "id": 3,
            "name": "Berlin"
          },
          {
            "id": 4,
            "name": "Brandenburg"
          },
          {
            "id": 5,
            "name": "Bremen"
          },
          {
            "id": 6,
            "name": "Hamburg"
          },
          {
            "id": 7,
            "name": "Hessen"
          },
          {
            "id": 8,
            "name": "Mecklenburg-Vorpommern"
          },
          {
            "id": 9,
            "name": "Niedersachsen"
          },
          {
            "id": 10,
            "name": "Nordrhein-Westfalen"
          },
          {
            "id": 11,
            "name": "Rheinland-Pfalz"
          },
          {
            "id": 12,
            "name": "Saarland"
          },
          {
            "id": 13,
            "name": "Sachsen"
          },
          {
            "id": 14,
            "name": "Sachsen-Anhalt"
          },
          {
            "id": 15,
            "name": "Schleswig-Holstein"
          },
          {
            "id": 16,
            "name": "Thüringen"
          }
        ]
      },
      {
        "id": 2,
        "name": "Avusturya",
        "states": [
          {
            "id": 17,
            "name": "Wien"
          },
          {
            "id": 18,
            "name": "Oberösterreich"
          },
          {
            "id": 19,
            "name": "Niederösterreich"
          },
          {
            "id": 20,
            "name": "Salzburg"
          },
          {
            "id": 21,
            "name": "Tirol"
          },
          {
            "id": 22,
            "name": "Vorarlberg"
          },
          {
            "id": 23,
            "name": "Burgenland"
          },
          {
            "id": 24,
            "name": "Steiermark"
          },
          {
            "id": 25,
            "name": "Kärnten"
          }
        ]
      },
      {
        "id": 3,
        "name": "Belçika",
        "states": [
          {
            "id": 26,
            "name": "Brüksel"
          },
          {
            "id": 27,
            "name": "Antwerpen"
          },
          {
            "id": 28,
            "name": "Oost-Vlanderen"
          },
          {
            "id": 29,
            "name": "Limburg"
          },
          {
            "id": 30,
            "name": "Hainaut"
          },
          {
            "id": 31,
            "name": "Vlaams-Brabant"
          },
          {
            "id": 32,
            "name": "Liège"
          },
          {
            "id": 33,
            "name": "West-Vlanderen"
          },
          {
            "id": 34,
            "name": "Luxembourg"
          },
          {
            "id": 35,
            "name": "Namur"
          },
          {
            "id": 36,
            "name": "Brabant wallon"
          }
        ]
      },
      {
        "id": 4,
        "name": "Fransa",
        "states": [
          {
            "id": 37,
            "name": "Grand Est"
          },
          {
            "id": 38,
            "name": "Ile-de-France"
          },
          {
            "id": 39,
            "name": "Auvergne-Rhône-Alpes"
          },
          {
            "id": 40,
            "name": "Centre-Val de Loire"
          },
          {
            "id": 41,
            "name": "Hauts-de-France"
          },
          {
            "id": 42,
            "name": "Bourgogne-Franche-Comté"
          },
          {
            "id": 43,
            "name": "Provence-Alpes-Côte d'Azur"
          },
          {
            "id": 44,
            "name": "Occitanie"
          },
          {
            "id": 45,
            "name": "Nouvelle-Aquitaine"
          },
          {
            "id": 46,
            "name": "Pays de la Loire"
          },
          {
            "id": 47,
            "name": "Bretagne"
          },
          {
            "id": 48,
            "name": "Normandie"
          }
        ]
      },
      {
        "id": 5,
        "name": "Hollanda",
        "states": [
          {
            "id": 49,
            "name": "Noord-Holland"
          },
          {
            "id": 50,
            "name": "Zuid-Holland"
          },
          {
            "id": 51,
            "name": "Utrecht"
          },
          {
            "id": 52,
            "name": "Noord-Brabant"
          },
          {
            "id": 53,
            "name": "Gelderland"
          },
          {
            "id": 54,
            "name": "Overijssel"
          },
          {
            "id": 55,
            "name": "Limburg"
          },
          {
            "id": 56,
            "name": "Fryslân"
          },
          {
            "id": 57,
            "name": "Flevoland"
          },
          {
            "id": 58,
            "name": "Groningen"
          },
          {
            "id": 59,
            "name": "Zeeland"
          },
          {
            "id": 60,
            "name": "Drenthe"
          }
        ]
      },
      {
        "id": 6,
        "name": "İsviçre",
        "states": [
          {
            "id": 61,
            "name": "İsviçre"
          }
        ]
      },
      {
        "id": 7,
        "name": "Danimarka",
        "states": [
          {
            "id": 62,
            "name": "Danimarka"
          }
        ]
      },
      {
        "id": 8,
        "name": "Lichtenstein",
        "states": [
          {
            "id": 63,
            "name": "Lichtenstein"
          }
        ]
      },
      {
        "id": 9,
        "name": "Luxemburg",
        "states": [
          {
            "id": 64,
            "name": "Luxemburg"
          }
        ]
      },
      {
        "id": 10,
        "name": "İtalya",
        "states": [
          {
            "id": 65,
            "name": "İtalya"
          }
        ]
      },
      {
        "id": 11,
        "name": "İspanya",
        "states": [
          {
            "id": 66,
            "name": "İspanya"
          }
        ]
      },
      {
        "id": 12,
        "name": "Portekiz",
        "states": [
          {
            "id": 67,
            "name": "Portekiz"
          }
        ]
      },
      {
        "id": 13,
        "name": "İngiltere",
        "states": [
          {
            "id": 68,
            "name": "İngiltere"
          }
        ]
      },
      {
        "id": 14,
        "name": "ABD",
        "states": [
          {
            "id": 69,
            "name": "ABD"
          }
        ]
      },
      {
        "id": 15,
        "name": "Bosna Hersek",
        "states": [
          {
            "id": 70,
            "name": "Bosna Hersek"
          }
        ]
      },
      {
        "id": 16,
        "name": "Makedonya",
        "states": [
          {
            "id": 71,
            "name": "Makedonya"
          }
        ]
      },
      {
        "id": 17,
        "name": "Arnavutluk",
        "states": [
          {
            "id": 72,
            "name": "Arnavutluk"
          }
        ]
      },
      {
        "id": 18,
        "name": "Kosova",
        "states": [
          {
            "id": 73,
            "name": "Kosova"
          }
        ]
      },
      {
        "id": 19,
        "name": "Türkiye",
        "states": [
          {
            "id": 74,
            "name": "Türkiye"
          }
        ]
      },
      {
        "id": 20,
        "name": "Güney Afrika",
        "states": [
          {
            "id": 75,
            "name": "Cape Town"
          },
          {
            "id": 76,
            "name": "Johannesburg"
          }
        ]
      }
    ])
    const regions = computed(() => {
          let tempRegions = [];
          if (country.value) {
            tempRegions = countries.value.find(x => x.id === country.value).states
            if (tempRegions.length >= 1 && !tempRegions.find(x => x.id === region.value)) {
              resetRegion()
            }
          }
          return tempRegions;
        }
    )

    //const requiredMsg = '⛔ This field is required'
    return {
      arrowForwardOutline,
      arrowBackOutline,
      helpCircle,
      personCircle,
      search,
      star,
      countries,
      regions,
      country,
      city,
      street,
      region,
      postalCode,
      errors: useFormErrors()
    }
  }
})
</script>