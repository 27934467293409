<template>
  <ion-content v-show="stepVisible" fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title size="small">Genç Kaşif Kayıt Formu</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-title>Veli Bilgileri</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-item>
      <ion-label position="stacked">Cinsiyet <span>{{ errors['parentStep.gender'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="gender" cancel-text="Kapat">
        <ion-select-option value="f">Kadın</ion-select-option>
        <ion-select-option value="m">Erkek</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Adı <span>{{ errors['parentStep.firstName'] }}</span></ion-label>
      <ion-input v-model="firstName" autocapitalize="words"/>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Soyadı <span>{{ errors['parentStep.lastName'] }}</span></ion-label>
      <ion-input v-model="lastName" autocapitalize="words"/>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Telefon <span>{{ errors['parentStep.phoneNumber'] }}</span></ion-label>
      <ion-input v-model="phoneNumber" type="tel"/>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">E-Mail <span>{{ errors['parentStep.email'] }}</span></ion-label>
      <ion-input v-model="email" type="email"/>
    </ion-item>

  </ion-content>
</template>

<script>
import {IonContent, IonHeader, IonInput, IonItem, IonLabel, IonTitle, IonToolbar, IonSelect, IonSelectOption} from "@ionic/vue";
import {arrowBackOutline, arrowForwardOutline, helpCircle, personCircle, search, star} from "ionicons/icons";
import {useField, useFormErrors} from "vee-validate";
import {defineComponent} from 'vue';
import * as yup from "yup";
import "yup-phone";

export const parentStepComponentSchema = yup
    .object({
      parentStep: yup.object({
        firstName: yup.string().required().label('This').min(3),
        lastName: yup.string().required().label('This').min(3),
        phoneNumber: yup.string().phone('', false, "Geçerli, uluslarası telefon formatı gerekiyor.").required().label('This'),
        email: yup.string().email().required().label('This'),
        gender: yup.string().required().label('This')
      })
    })
    .required();

export default defineComponent({
  name: "parentStep",
  props: {
    'stepVisible': Boolean,
    'form': Object,
    'currentStep': Number,
    'totalSteps': Number
  },
  components: {
    IonInput, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonSelect, IonSelectOption
  },
  setup() {
    const {value: firstName} = useField('parentStep.firstName');
    const {value: lastName} = useField('parentStep.lastName');
    const {value: phoneNumber} = useField('parentStep.phoneNumber');
    const {value: email} = useField('parentStep.email');
    const {value: gender} = useField('parentStep.gender');
    //const requiredMsg = '⛔ This field is required'
    return {
      arrowForwardOutline,
      arrowBackOutline,
      helpCircle,
      personCircle,
      search,
      star,
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      errors: useFormErrors()
    }
  }
})
</script>