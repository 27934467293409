<template>
  <ion-content v-show="stepVisible" fullscreen="true">
    <ion-header>
      <ion-toolbar>
        <ion-title size="small">Genç Kaşif Kayıt Formu</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-title>Talebe Bilgileri</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-item>
      <ion-label position="stacked">Adı <span>{{ errors['studentStep.firstName'] }}</span></ion-label>
      <ion-input v-model="firstName" autocapitalize="words"/>
    </ion-item>

    <ion-item>
      <ion-label position="stacked">Soyadı <span>{{ errors['studentStep.lastName'] }}</span></ion-label>
      <ion-input v-model="lastName" autocapitalize="words"/>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Cinsiyet <span>{{ errors['studentStep.gender'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="gender" cancel-text="Kapat">
        <ion-select-option value="f">Kız</ion-select-option>
        <ion-select-option value="m">Erkek</ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item>
      <ion-label position="floating">Doğum tarihi <span>{{ errors['studentStep.dateOfBirth'] }}</span></ion-label>
      <ion-datetime
          v-model="dateOfBirth"
          min="1990"
          max="2016"
          placeholder="Seçiniz"
          display-format="DD MMM YYYY"
          month-short-names="Ocak, Şubat, Mart, Nisan, Mayıs, Haziran, Temmuz, Ağustos, Eylül, Ekim, Kasım, Aralık"
      ></ion-datetime>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Kur'an-ı Kerim Bilgileriniz <span>{{ errors['studentStep.quran'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="quran" cancel-text="Kapat">
        <ion-select-option value="1">Elif Cüzündeyim</ion-select-option>
        <ion-select-option value="2">Kuran'ı yüzünden okuyabiliyorum</ion-select-option>
        <ion-select-option value="3">Tecvid Biliyorum</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Türkçe Seviyeniz <span>{{ errors['studentStep.turkish'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="turkish" cancel-text="Kapat">
        <ion-select-option value="1">Okuyup Yazabiliyorum</ion-select-option>
        <ion-select-option value="2">Okuyabiliyorum</ion-select-option>
        <ion-select-option value="3">Yazabiliyorum</ion-select-option>
        <ion-select-option value="4">Bilmiyorum</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Ezberleriniz <span>{{ errors['studentStep.memorization'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="memorization" cancel-text="Kapat">
        <ion-select-option value="1">Kısa ve uzun sureler biliyorum</ion-select-option>
        <ion-select-option value="2">Kısa sureleri biliyorum</ion-select-option>
        <ion-select-option value="3">Hiç bilmiyorum</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item>
      <ion-label position="floating">Kaçıncı sınıfa gidiyorsunuz <span>{{ errors['studentStep.grade'] }}</span></ion-label>
      <ion-select interface="action-sheet" placeholder="Seçiniz" v-model="grade" cancel-text="Kapat">
        <ion-select-option value="1">1. Sınıf</ion-select-option>
        <ion-select-option value="2">2. Sınıf</ion-select-option>
        <ion-select-option value="3">3. Sınıf</ion-select-option>
        <ion-select-option value="4">4. Sınıf</ion-select-option>
        <ion-select-option value="5">5. Sınıf</ion-select-option>
        <ion-select-option value="6">6. Sınıf</ion-select-option>
        <ion-select-option value="7">7. Sınıf</ion-select-option>
        <ion-select-option value="8">8. Sınıf</ion-select-option>
      </ion-select>
    </ion-item>
  </ion-content>
</template>

<script>
import {
  IonContent,
  IonDatetime,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/vue";
import {arrowBackOutline, arrowForwardOutline, helpCircle, personCircle, search, star,} from "ionicons/icons";
import {useField, useFormErrors} from "vee-validate";
import {defineComponent} from 'vue';
import * as yup from "yup";

export const studentStepComponentSchema = yup
    .object({
      studentStep: yup.object({
        firstName: yup.string().required().label('This').min(3),
        lastName: yup.string().required().label('This').min(3),
        gender: yup.string().required().label('This'),
        dateOfBirth: yup.date().required().label('This'),
        quran: yup.number().required().label('This'),
        turkish: yup.number().required().label('This'),
        memorization: yup.number().required().label('This'),
        grade: yup.number().required().label('This')
      })
    })
    .required();

export default defineComponent({
  name: "studentStep",
  props: {
    'stepVisible': Boolean,
    'form': Object,
    'currentStep': Number,
    'totalSteps': Number
  },
  components: {
    IonInput, IonHeader, IonToolbar, IonTitle, IonContent, IonDatetime, IonItem, IonLabel,
    IonSelect,
    IonSelectOption
  },
  setup() {
    const {value: firstName} = useField('studentStep.firstName');
    const {value: lastName} = useField('studentStep.lastName');
    const {value: gender} = useField('studentStep.gender');
    const {value: dateOfBirth} = useField('studentStep.dateOfBirth');
    const {value: quran} = useField('studentStep.quran');
    const {value: turkish} = useField('studentStep.turkish');
    const {value: memorization} = useField('studentStep.memorization');
    const {value: grade} = useField('studentStep.grade');
    //const requiredMsg = '⛔ This field is required'
    return {
      arrowForwardOutline,
      arrowBackOutline,
      helpCircle,
      personCircle,
      search,
      star,
      firstName,
      lastName,
      gender,
      dateOfBirth,
      quran,
      turkish, memorization, grade,
      errors: useFormErrors()
    }
  }
})
</script>